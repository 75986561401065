import * as React from 'react'
import { graphql, Script } from 'gatsby'
import PropTypes from 'prop-types'
import loadable from '@loadable/component'
import parse from 'html-react-parser'

const ContactSidebar = loadable(() => import('/src/components/Sidebars/ContactSidebar'))
const FormSelector = loadable(() => import('/src/components/Form/FormSelector'))
const LayoutInner = loadable(() => import('/src/components/Layout/LayoutInner'))
const PageBuilder = loadable(() => import('/src/components/PageBuilder'))
const PageHeader = loadable(() => import('/src/components/Structure/PageHeader'))
const Section = loadable(() => import('/src/components/Structure/Section/Section'))
const Seo = loadable(() => import('/src/components/Structure/Seo'))

const SidebarPage = ({ post }) => {
    const hasForm = post.formPage?.form
    const hasPageBuilder = post.pageBuilder

    const breadcrumb = {
        type: 'standard',
        pageParent: post.wpParent
    }

    const content = post.acfPageContent?.content

    return (
        <LayoutInner>
            {post.acfPage.addExternalScript && <Script src={post.acfPage.scriptSourceUrl} />}

            <Seo post={post} />

            <main className="c-form-page">
                <article itemScope itemType="http://schema.org/Article">
                    <PageHeader
                        breadcrumb={breadcrumb}
                        pageTitle={post.title}
                        description={post.pageHeader.description}
                    />

                    <Section size={'sm'} itemProp={'articleBody'}>
                        <div className="container-lg">
                            <div className="row">
                                <div className="col-lg-7 col-xl-6 offset-xl-1">
                                    {content && parse(content)}

                                    {hasForm && (
                                        <FormSelector
                                            form={post.formPage.form}
                                            disclaimer={post.formPage.formDisclaimer}
                                        />
                                    )}
                                </div>
                                <div className="col-lg-5 col-xl-4">
                                    <aside>
                                        <ContactSidebar />
                                    </aside>
                                </div>
                            </div>
                        </div>
                    </Section>

                    {hasPageBuilder && <PageBuilder post={post} />}
                </article>
            </main>
        </LayoutInner>
    )
}

SidebarPage.propTypes = {
    /**
     * Post object
     */
    post: PropTypes.object.isRequired
}

export default SidebarPage

export const query = graphql`
    fragment FormPage on WpPage {
        formPage: acfFormPage {
            form
            formDisclaimer
        }
    }
`
